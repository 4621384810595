import PropTypes from 'prop-types';
import Link from './link';

const Links = ({ links, className, ...rest }) => (
  <div className={className}>
    {links.map((link) => <Link {...link} {...rest} />)}
  </div>
);

Links.propTypes = {
  className: PropTypes.string,
  links: PropTypes.instanceOf(Array).isRequired,
};

Links.defaultProps = {
  className: '',
};

export default Links;
