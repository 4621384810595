import PropTypes from 'prop-types';
import { t, locale } from 'bv-i18n';

const SessionInfo = ({ lastLogin }) => {
  const tKeyPrefix = 'javascript.account.overview.session_info';

  const dateLastLogin = new Date(lastLogin);
  const dateLastSeen = new Date();
  const duration = `${Math.ceil(Math.abs(dateLastSeen - dateLastLogin) / (1000 * 60))} ${t(`${tKeyPrefix}.minutes`)}`;

  if (!lastLogin) return null;

  return (
    <div className="acc-overview__session-info">
      <div className="acc-overview__session-flex">
        <span>
          {t(`${tKeyPrefix}.last_login`, {
            time: dateLastLogin.toLocaleString(locale(), {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          })}
        </span>
        <span>
          {t(`${tKeyPrefix}.duration`, { duration })}
        </span>
      </div>
    </div>
  );
};

SessionInfo.propTypes = {
  lastLogin: PropTypes.string.isRequired,
};

export default SessionInfo;
