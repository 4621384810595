import PropTypes from 'prop-types';
import { FeatureGuardComponent, Icon } from 'bv-components';
import { t } from 'bv-i18n';
import CountInfo from './count_info';
import Badge from './badge';

const Link = ({
  href, icon, tKey, count, badgeTranslationKey, ...rest
}) => (
  <a className="acc-overview__link" href={href}>
    <Icon
      className="acc-overview__link-icon"
      id={(typeof icon === 'function' ? icon(rest) : icon)}
    />
    <span className="acc-overview__link-text">
      {t(tKey)}
      {count && <CountInfo {...count} />}
    </span>
    {badgeTranslationKey(rest) && <Badge tKey={badgeTranslationKey(rest)} /> }
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  badgeTranslationKey: PropTypes.func,
  tKey: PropTypes.string.isRequired,
  count: PropTypes.instanceOf(Object),
};

Link.defaultProps = {
  count: null,
  badgeTranslationKey: () => {},
};

const FeatureGuardedLink = ({ featureName, ...rest }) => {
  const link = <Link {...rest} />;

  if (!featureName) return link;

  return (
    <FeatureGuardComponent feature={featureName}>
      {link}
    </FeatureGuardComponent>
  );
};

FeatureGuardedLink.propTypes = {
  featureName: PropTypes.string,
};

FeatureGuardedLink.defaultProps = {
  featureName: null,
};

export default FeatureGuardedLink;
