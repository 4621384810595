import PropTypes from 'prop-types';
import { Icon, Link } from 'bv-components';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

const Header = ({
  firstName, accountNumber, decimalMainBalance, decimalBonusBalance, hasBonusBalance,
}) => (
  <div className="acc-overview__header">
    <div className="acc-overview__flex acc-overview__acc-data">
      <span>{firstName}</span>
      <Icon className="acc-overview__user-icon" />
      <span>{accountNumber}</span>
    </div>
    { ((decimalMainBalance || decimalMainBalance === 0)
       || (decimalBonusBalance || decimalBonusBalance === 0)) && (
       <div className="acc-overview__flex">
         <span className="bold">{`${t('javascript.balance.main')} ${toCurrency(decimalMainBalance, { decimalPlaces: 2 })}`}</span>
         <span className="acc-overview__separator" />
         <span>
           {`${t('javascript.balance.bonus')} ${toCurrency(decimalBonusBalance, { decimalPlaces: 2 })}`}
           <Link to={`/offer${hasBonusBalance ? '/wallets' : ''}`}>
             <Icon big className="acc-overview__bonus-info-icon" />
           </Link>
         </span>
       </div>
    )}
  </div>
);

Header.propTypes = {
  firstName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  decimalMainBalance: PropTypes.number.isRequired,
  decimalBonusBalance: PropTypes.number.isRequired,
  hasBonusBalance: PropTypes.bool.isRequired,
};

export default Header;
