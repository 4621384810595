import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Badge = ({ tKey }) => (
  <div className="acc-overview__link-badge--withtext">
    {t(tKey)}
  </div>
);

Badge.propTypes = {
  tKey: PropTypes.string.isRequired,
};

export default Badge;
