import { hasSportsbook } from 'global-config';
import { property } from 'underscore';

export default [
  {
    tKey: `javascript.account.overview.${hasSportsbook ? 'my_bets' : 'game_history'}`,
    icon: 'my-bets',
    href: '/bv_cashier/history',
  },
  {
    tKey: 'my_transactions_title',
    icon: 'my-transactions',
    href: '/my_transactions',
    featureName: 'my_transactions',
    badgeTranslationKey: (props) => (props.showMyTransactionsBadge ? 'my_transactions_badge' : undefined),
  },
  {
    tKey: 'javascript.account.overview.lottery',
    icon: 'lottery',
    href: '/bv_cashier/lottery/6',
    featureName: 'lottoland',
  },
  {
    tKey: 'javascript.account.overview.banking_history',
    icon: 'banking-history',
    href: '/bv_cashier/history/5',
  },
  {
    tKey: 'javascript.kyc.title',
    icon: (props) => (props.showVerificationZoneIcon ? 'account-verification-active' : 'account-verification'),
    href: '/account/verification_zone',
  },
  {
    tKey: 'javascript.sg.portal.modal_title',
    icon: 'sg-portal',
    href: '/safer_gambling/portal',
  },
  {
    tKey: 'javascript.refer_a_friend.modal_title',
    icon: 'raf',
    href: '/refer_a_friend',
    featureName: 'refer_a_friend',
  },
  {
    tKey: 'javascript.refer_a_friend.modal_title',
    icon: 'raf',
    href: '/refer_a_friend',
    featureName: 'refer_a_friend_asia',
  },
  {
    tKey: 'javascript.personal_details.account_overview_link',
    icon: 'edit-acc',
    href: '/personal_details',
  },
  {
    tKey: 'settings',
    icon: 'settings',
    href: '/preferences/edit',
  },
  {
    tKey: 'change_password',
    icon: 'change-password',
    href: '/account/password_change',
    onlyIf: property('hasPassword'),
  },
];
