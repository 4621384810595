import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const LogoutButton = () => (
  <div className="acc-overview__logout">
    <a href="/logout">
      <Button className="acc-overview__logout-btn" label={t('logout')} inverse />
    </a>
  </div>
);

export default LogoutButton;
