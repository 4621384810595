import { useEffect } from 'react';
import { pick } from 'underscore';
import { withFetching } from 'bv-hocs';
import { Provider } from 'react-redux';
import { set as setBalance } from 'bv-services/account-balance';
import fetchAccount from './api/fetch_account';
import Context from './context';
import Header from './components/header';
import TopLinks from './components/top_links';
import ListLinks from './components/list_links';
import LogoutButton from './components/logout_button';
import SessionInfo from './components/session_info';

const { store } = window.reduxState;

const App = (account) => {
  const { myTransactionsBadge } = account;
  const balanceFields = pick(account, 'mainBalance', 'decimalMainBalance', 'bonusBalance', 'decimalBonusBalance');

  useEffect(() => {
    setBalance(balanceFields);
  }, Object.values(balanceFields));

  return (
    <Context.Provider value={account}>
      <Header {...account} />
      <TopLinks />
      <Provider store={store}>
        <ListLinks myTransactionsBadge={myTransactionsBadge} />
      </Provider>
      <LogoutButton />
      <SessionInfo {...account} />
    </Context.Provider>
  );
};

App.displayName = 'AccountOverviewApp';

export default withFetching(
  fetchAccount,
  null,
  { spread: true },
)(App);
