import { useContext } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Links from './links';
import listLinks from '../constants/list_links';
import Context from '../context';

const filterLinks = (links, account) => (
  links.filter((link) => (
    !link.onlyIf || link.onlyIf(account)
  ))
);

const ListLinks = ({
  showKycWarning,
  myTransactionsBadge,
}) => {
  const account = useContext(Context);

  return (
    <Links
      className="acc-overview__list-links"
      links={filterLinks(listLinks, account)}
      showVerificationZoneIcon={showKycWarning}
      showMyTransactionsBadge={myTransactionsBadge}
    />
  );
};

ListLinks.propTypes = {
  showKycWarning: PropTypes.bool.isRequired,
  myTransactionsBadge: PropTypes.bool,
};
ListLinks.defaultProps = {
  myTransactionsBadge: null,
};

const mapStateToProps = (state) => ({
  showKycWarning: state.session.showKycBanner,
});

export default connect(mapStateToProps, null)(ListLinks);
