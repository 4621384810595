import PropTypes from 'prop-types';
import Context from '../context';

const CountInfo = ({ from }) => (
  <Context.Consumer>
    {(account) => {
      const count = account[from];

      return count ? (
        <div className="acc-overview__link-badge">{count}</div>
      ) : null;
    }}
  </Context.Consumer>
);

CountInfo.propTypes = {
  from: PropTypes.string.isRequired,
};

export default CountInfo;
